import React from "react"
import SEO from '../../components/seo'

import Layout from '../../layouts/layout'


export default () => (


  <Layout>

	<SEO title="Javascriptをオンにしてください"/>

	<main id="wrapper" class="seigen">

	<h1><a href="https://www.google.com/search?q=javascript+%E3%82%AA%E3%83%B3" target="_blank" rel="noopener noreferrer">javascriptをオン</a>にしてください</h1>
			<h3>このページを閲覧するには、javascriptをオンにする必要があります</h3>
			<p>「戻る」ボタンでは戻れませんので、再度URLを開き直して下さい。</p>

	</main>


  </Layout>
)